import React from 'react'
import { Router } from '@reach/router'
import { login, isAuthenticated, getUserInfo } from '../components/util/auth'
import '../css/account.scss'
import { Link, navigate } from 'gatsby'
import SEO from '../components/seo'
import MyAccount from '../components/myAccount/myAccount.component.jsx'
import Billing from '../components/billing/billing.component.jsx'
import History from '../components/history/history.component.jsx'
//import createBillingDashboardSession from '../components/util/createBillingDashboardSession';
import createStripePortalSession from '../components/util/createStripePortalSession'
import Profile from '../components/Profile'
import AccountNav from '../components/accountNav/accountNav.component.jsx'
import Subscriptions from '../components/subscriptions/subscriptions.component'
import { useQueryParam, StringParam } from 'use-query-params'
import Layout from '../components/layout'
import { Navbar } from '../components/navbar'
import { useStripe } from '@stripe/react-stripe-js'
import withUser from '../components/with-user/with-user.component'

const Home = () => {
	const user = getUserInfo()
	// console.log('user', user)
	return <p>Hi, {user ? user.name : 'friend'}!</p>
}
const Settings = () => <p>Settings</p>
// const Billing = () => <p>Redirecting to Stripe Customer Portal</p>

const Account = ({ data }) => {
	const user = getUserInfo()
	//console.log(user);
	const [tab = 'info', setTab] = useQueryParam('tab', StringParam)
	if (!isAuthenticated()) {
		login()
		return <p>Redirecting to login...</p>
	}
	const initializeUser = () => {
		const user = getUserInfo()
		const full_name = user?.nickname || ''
		//var stripeId = user?.user_metadata?.stripe || 'cus_IhZkmJHgnKKlok';
		const stripeId =
			process.env.NODE_ENV === 'production'
				? user?.['https://icloud-ready.com/stripe_customer_id']
				: user?.['https://icloud-ready.com/stripe_customer_id2']
		return stripeId
	}

	// const stripeId = initializeUser();

	const startBillingDashboard = ({ stripeId }) => {
		createStripePortalSession(stripeId)
			.then(res => res.json())
			.then(data => navigate(data.customer.url))
			.catch(err => console.error(err))
	}

	let content

	if (tab === 'subscriptions') {
		content = <Subscriptions user={user} />
	} else if (tab === 'history') {
		content = <History user={user} />
	} else if (tab === 'billing') {
		content = <Billing user={user} />
	} else {
		content = <MyAccount accountData={user} />
	}

	return (
		<>
			<Navbar />
			<Layout>
				<SEO title="Account" description="Your Account page" />

				<div className="account-page">
					<div className="Header-parent">
						{/* <MDXRenderer>{data.Header.nodes[0].body}</MDXRenderer> */}
					</div>

					<div className="page-content">
						<div className="account-wrapper">
							<AccountNav active={tab} setActive={setTab} />

							<div
								style={{
									padding: '16px',
									flexGrow: '1',
									overflowX: 'auto',
								}}
							>
								{content}
							</div>
						</div>
					</div>
				</div>
			</Layout>
		</>
	)
}

export default Account
// export default withUser(Account);
