import React from 'react';
import './accountNav.styles.scss';
import user from '../../images/user_account.svg';
import userActive from '../../images/user_account_active.svg';
import subscriptions from '../../images/subscriptions.svg';
import subscriptionsActive from '../../images/subscriptions_active.svg';
import billing from '../../images/billing.svg';
import billingActive from '../../images/billing_active.svg';
import inform from '../../images/inform.svg';
import logoutIcon from "../../images/logout.svg";
import informActive from '../../images/inform_active.svg';
import { logout } from "../util/auth";
import { HomeOutlined,  LogoutOutlined, ShoppingOutlined, WalletOutlined, HistoryOutlined } from '@ant-design/icons';

const links = [
  {
    icon: <HomeOutlined />,
    tab: "info"
  },
  {
    icon: <ShoppingOutlined />,
    tab: "subscriptions"
  },
  {
    icon: <HistoryOutlined />,
    tab: "history"
  },
  {
    icon: <WalletOutlined />,
    tab: "billing"
  }
];

function MobileAccountNav({ active, setActive }){
  return (
    <div className="mobile-account-nav">
      <nav className="mobile-account-nav-wrapper">
        {
          links.map(link => {
            return (
              <div 
                onClick={() => setActive(link.tab)}
                className={active === link.tab ? "selected" : ""}
              >
                {link.icon}
              </div>
            )
          })
        }

        <div onClick={() => logout()}>
          <LogoutOutlined />
        </div>
      </nav>
    </div>
  )
}

const AccountNAv = ({ active, setActive }) => {
  return (
    <>
      <MobileAccountNav active={active} setActive={setActive} />

      <div className="account-nav">
      <p className="title">My Account</p>
      <div className="nav-parent">
        <div
          className={`${active === 'info' ? 'nav-link active' : 'nav-link'}`}
          onClick={() => setActive('info')}
          onChange={() => {}}
          aria-hidden="true"
        >
          <img src={active === 'info' ? userActive : user} alt="user" />
          <p>Personal Info</p>
        </div>
        <div
          className={`${
            active === 'subscriptions' ? 'nav-link active' : 'nav-link'
          }`}
          onClick={() => setActive('subscriptions')}
          onChange={() => {}}
          aria-hidden="true"
        >
          <img
            src={
              active === 'subscriptions' ? subscriptionsActive : subscriptions
            }
            alt="Subscriptions"
          />
          <p>Subscriptions</p>
        </div>
        <div
          className={`${active === 'history' ? 'nav-link active' : 'nav-link'}`}
          onClick={() => setActive('history')}
          onChange={() => {}}
          aria-hidden="true"
        >
          <img
            src={active === 'history' ? billingActive : billing}
            alt="Billing History"
          />
          <p>Billing History</p>
        </div>
        <div
          className={`${active === 'billing' ? 'nav-link active' : 'nav-link'}`}
          onClick={() => setActive('billing')}
          onChange={() => {}}
          aria-hidden="true"
        >
          <img
            src={active === 'billing' ? informActive : inform}
            alt="Billing Information"
          />
          <p>Billing Information</p>
        </div>

        <div
          className="nav-link active"
          onClick={() => logout()}
          aria-hidden="true"
          style={{
            marginTop: "30px",
            background: "#0097c2"
          }}
        >
          <img 
            src={logoutIcon}
            alt="logout"
          />
          <p>Logout</p>
        </div>
      </div>
      </div>
    </>
  );
};

export default AccountNAv;
