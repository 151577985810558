import React, { useEffect, useState } from 'react';
import './history.styles.scss';
import filters from '../../images/filters.svg';
import rows from '../../images/row.svg';
import prev from '../../images/prev.svg';
import next from '../../images/next.svg';
import fetchCustomerInvoices from '../util/fetchCustomerInvoices';

function calcTotalUnpaidAmount(invoices){
  const sum = invoices.reduce((acc, invoice) => {
    return acc + (invoice.status !== "paid" ? invoice.amount.value : 0) / 100
  }, 0);

  return sum.toFixed(2) + "$";
}

const History = ({ user }) => {
  const [invoices, setInvoices] = useState([]);
  const [invoicesLines, setInvoicesLines] = useState([]);
  const stripeId =
  process.env.NODE_ENV === 'production'
    ? user?.['https://icloud-ready.com/stripe_customer_id']
    : user?.['https://icloud-ready.com/stripe_customer_id2']
  

  console.log(invoices);

  useEffect(() => {
    fetchCustomerInvoices({ stripeId })
      .then(res => res.json())
      .then(({ data }) => {
        // console.log("fetched invoices raw data: "+JSON.stringify(data))
        if (Array.isArray(data?.lines?.data)) {
          //  console.log("fetched invoices lines raw data: "+JSON.stringify(data?.lines?.data))
          let map = Array.prototype.map;
          let invoicesLinesd = map.call(
            data?.lines?.data,
            function (invoicesLinesData) {
              let rObj = {};
              rObj['id'] = invoicesLinesData.id;
              rObj['description'] = invoicesLinesData.description;
              rObj['currency'] = invoicesLinesData.currency;
              rObj['invoice_item_id'] = invoicesLinesData.invoice_item;
              rObj['invoice_period_start'] = invoicesLinesData.period?.start;
              rObj['invoice_period_end'] = invoicesLinesData.period?.end;
              rObj['amount'] = invoicesLinesData.amount;
              rObj['quantity'] = invoicesLinesData.quantity;
              rObj['subscription'] = invoicesLinesData.subscription;
              //   console.log("new invoicesLinesData inside mapfunction:   "+JSON.stringify(rObj));
              return rObj;
            }
          );

          setInvoicesLines(invoicesLinesd);
        }
        let map = Array.prototype.map;
        let invoicesd = map.call(data, function (invoicesData) {
          let rObj = {};
          rObj['id'] = invoicesData.id;
          rObj['application'] = {
            name: invoicesData.description,
            id: invoicesData.id,
          };
          rObj['paymentStatus'] = {
            status: invoicesData.status,
            time: new Date(invoicesData?.period_start * 1000)?.toLocaleString(),
          };
          rObj['userStatus'] = {
            active: invoicesData.status,
            time: new Date(invoicesData?.period_start * 1000)?.toLocaleString(),
          };
          rObj['currency'] = invoicesData.currency;
          rObj['paid'] = invoicesData.paid;
          rObj['status'] = invoicesData.status;
          rObj['number'] = invoicesData.number;
          rObj['hosted_invoice_url'] = invoicesData.hosted_invoice_url;
          rObj['date'] = new Date(
            invoicesData?.period_start * 1000
          )?.toLocaleString();
          rObj['amount'] = {
            value: invoicesData.amount_due,
            currency: invoicesData.currency,
          };
          rObj['amount_paid'] = invoicesData.amount_paid / 100;
          rObj['quantity'] = invoicesData.quantity;
          rObj['subscriptionId'] = invoicesData.subscription;
          rObj['invoice_pdf'] = invoicesData.invoice_pdf;
          rObj['plan_nickname'] = invoicesData?.lines?.data[0]?.plan?.nickname || 'N/A';
          //  console.log("new invoicesData inside mapfunction:   "+JSON.stringify(rObj));
          return rObj;
        });
        // console.log(
        //   'fetched invoices mapped data: ' + JSON.stringify(invoicesd)
        // );
        // setInvoices(data);
        setInvoices(invoicesd);
      })
      .catch(error => console.error("Couldn't get invoices:", error));
  }, [stripeId]);

  return (
    <div className="my-history">
      <p className="title">Welcome {user.name}</p>
      <div className="history-parent">
        <div className="headers">
          {/* <div className="filters">
            <p className="filter active">All</p>
            <p className="filter">Paid</p>
            <p className="filter">Unpaid</p>
            <p className="filter">Overdue</p>
          </div> */}
          <div className="amount">
            <p className="total">
              Total unpaid amount: <span className="value">{calcTotalUnpaidAmount(invoices)}</span>{' '}
              <span className="currancy">USD</span>
            </p>
          </div>
        </div>
        <div className="history-table">
          {/*
          ** temp remove search and filter ** 
          <div className="table-control">
            <div className="filters">
              <img src={filters} alt="filters" width="20" height="20" />
              <span>Filter</span>
            </div>
            <div className="search">
              <input type="search" />
            </div>
          </div> */}
          <div className="table-header">
            {/* <div className="check-parent">
              <input type="checkbox" className="check" />
            </div> */}
            <div className="application top">APPLICATION</div>
            {/* <div className="user top">USER STATUS</div> */}
            <div className="payment top">PAYMENT STATUS</div>
            <div className="date top">ISSUE DATE</div>
            <div className="amount top">AMOUNT</div>
            <div className="action top"></div>
          </div>
          <div className="table-content">
            {invoices.map((item, index) => (
                <div className="table-row" key={index}>
                  {/* <div className="check-parent">
                    <input type="checkbox" className="check" />
                  </div> */}
                  <div className="application">
                    <span>{item.plan_nickname}</span>
                    <p>ID: {item.application.id}</p>
                  </div>
                  {/* <div className="user">
                    <span
                      className={item.userStatus.active.toLowerCase() + '-user'}
                    >
                      <span className="dot">&#9679; </span>
                      {item.userStatus.active}
                    </span>
                    <p>Last login: {item.userStatus.time}</p>
                  </div> */}
                  <div className="payment">
                    <span className={item.paymentStatus.status.toLowerCase()}>
                      <span className="dot">&#9679; </span>
                      {item.paymentStatus.status}
                    </span>
                    {/* <p>Paid on {item.paymentStatus.time}</p> */}
                  </div>
                  <div className="date">
                    <p>{item.date}</p>
                  </div>
                  <div className="amount">
                    <span>{item.amount.value / 100}</span>
                    <p>{item.amount.currency}</p>
                  </div>
                  <div className="action">
                    <a href={item.invoice_pdf} target="_blank">Print Invoice</a>
                  </div>
                </div>
              ))}
          </div>
          <div className="table-footer">
            <p className="rows">
              <span>Rows per page: 10</span>
              <img src={rows} alt="rows" />
            </p>
            <p className="pages">1-10 of 276</p>
            <div className="table-nav">
              <span>
                <img src={prev} alt="prev" />
              </span>
              <span>
                <img src={next} alt="next" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
