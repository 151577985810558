import React from "react";
import { Button } from "antd";
import "./proceed-dialog.styles.scss";

function DialogBox({ question, accept, close, isOpened }){
    const wrapperRef = React.useRef();

    React.useEffect(
        () => {
            document.body.appendChild(wrapperRef.current);

            return () => document.body.removeChild(wrapperRef.current);
        },
        []
    )

    return (
        <div className={`dialog-wrapper ${!isOpened ? "hidden" : ""}`} ref={wrapperRef}>
            <div className="dialog">
                <p>{question}</p>
                <div className="actions">
                    <Button shape="round" size="large" className="no" onClick={close} type="default" >cancel</Button>
                    <Button shape="round" size="large" className="yes" onClick={accept} type="primary">yes</Button>
                </div>
            </div>
        </div>
    )
}

function ProceedDialog({ question, onClick, children, ...delegated }){
    const [isOpened, setIsOpened] = React.useState(false);

    const close = React.useCallback(() => setIsOpened(false), []);
    const open = React.useCallback(() => setIsOpened(true), []);
    const accept = () => {
        close();
        onClick();
    }

    const btn = React.cloneElement(children, {onClick: open, ...delegated});

    return (
        <>
            {btn}
            {/* {
                isOpened 
                && */}
                <DialogBox 
                    close={close}
                    accept={accept}
                    question={question}
                    isOpened={isOpened}
                />
            {/* } */}
        </>
    )
}

export default ProceedDialog;