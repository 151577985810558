import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './async-container.styles.scss';

function ErrorMessage({ err }) {
  if (err.type === 'NOT_FOUND') {
    return (
      <div className="msg-with-icon">
        <img src={emptyFolder} alt="empty folder" />
        <p>{err.message}</p>
      </div>
    );
  }

  return <p>{err.message}</p>;
}

function AsyncContainer({ isLoading, isError, error, children, ...delgated }) {
  if (isLoading) {
    return (
      <div className="async-wrapper" {...delgated}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="async-wrapper" {...delgated}>
        {String(error) || error.message}
      </div>
    );
  }

  return children();
}

export default AsyncContainer;
