import ExploreButton from '../explore-button/explore-button'
import React, { useState } from 'react'
import { useCustomer } from '../../hooks/stripe'
import './myAccount.styles.scss'

const MyAccount = ({ accountData }) => {
	const {
		picture,
		name: fullName,
		nickname: userName,
		email,
		mobile,
		companyName,
		companyWebSite,
		companyImage,
	} = accountData
	console.log('picture', picture)
	const { data: customer } = useCustomer()

	// console.log('account: ', customer)

	return (
		<div className="my-account">
			<p className="title">Welcome {fullName}</p>
			<div className="account-parent">
				<div className="personal-data">
					<div className="photo-parent">
						<div className="my-photo">
							{picture ? (
								<img
									src={picture}
									height="100"
									width="100"
									alt="myphoto"
									referrerPolicy="no-referrer"
								/>
							) : (
								<span>{fullName?.substring(0, 1)}</span>
							)}
						</div>
					</div>
					<div className="personal">
						<div className="account-row">
							<label htmlFor="fullName">
								Full Name
								<input
									className="account-field"
									type="text"
									value={fullName}
									id="fullName"
									name="fullName"
									readOnly
								/>
							</label>
							<label htmlFor="userName">
								User Name
								<input
									className="account-field"
									type="text"
									value={userName}
									id="userName"
									name="userName"
									readOnly
								/>
							</label>
						</div>
						<div className="account-row">
							<label htmlFor="email">
								Email Address
								<input
									className="account-field"
									type="text"
									value={email}
									id="email"
									name="email"
									readOnly
								/>
							</label>

							<label htmlFor="email">
								Organization ID
								<input
									className="account-field"
									type="text"
									value={customer?.metadata?.realmId}
									id="realmId"
									name="realmId"
									readOnly
								/>
							</label>
							{/* <label htmlFor="mobile">
                Mobile Number
                <input
                  className="account-field"
                  type="text"
                  value={mobile}
                  id="mobile"
                  name="mobile"
                  readOnly
                />
              </label> */}
						</div>

						<div className="account-row">
							<label htmlFor="phone">
								Phone
								<input
									className="account-field"
									type="text"
									value={customer?.metadata?.phone}
									id="phone"
									name="phone"
									readOnly
								/>
							</label>
						</div>
					</div>
				</div>
				<div
					className="company-data"
					style={{ display: companyName || customer?.metadata.company ? 'block' : 'none' }}
				>
					<p className="hint">Company Information</p>
					<div className="photo-parent">
						<div className="company-photo">
							{companyImage ? (
								<img
									src={companyImage}
									alt="company image"
									referrerPolicy="no-referrer"
									width={100}
									height={100}
								/>
							) : (
								<span>
									{companyName?.[0] || customer?.metadata?.company?.[0]}
								</span>
							)}
						</div>
					</div>
					<div className="company">
						<div className="account-row">
							<label htmlFor="company">
								Company
								<input
									className="account-field"
									type="text"
									value={companyName || customer?.metadata.company}
									id="company"
									name="company"
									readOnly
								/>
							</label>
							<label htmlFor="website">
								Company Website
								<input
									className="account-field"
									type="text"
									value={
										companyWebSite ||
										customer?.metadata.companyWebsite
									}
									id="website"
									name="website"
									readOnly
								/>
							</label>
						</div>
					</div>
				</div>

				<div style={{ maxWidth: '200px' }}>
					<ExploreButton />
				</div>
			</div>
		</div>
	)
}

export default MyAccount
