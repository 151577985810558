import React from 'react'
import './card.styles.scss'
import visa from '../../images/bank_VISA.svg'
import master from '../../images/bank_Mastercard.svg'
import amex from '../../images/bank_AMEX.svg'
import discover from '../../images/bank_Discover.svg'
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js'
import COUNTRIES_LIST from '../../utils/countries'
import { Select } from 'antd'

const Card = ({ billingDetails, setBillingDetails, cardError }) => {
	// console.log(billingDetails);
	// console.log(`from card component: ${cardError?.message}`)
	return (
		<div className="my-card">
			{cardError && (
				<div className="err-msg">{cardError.message || cardError.error || String(cardError)}</div>
			)}
			<div className="account-row">
				<label className="full" htmlFor="cardNumber">
					<img src={discover} alt="discover" className="one" />
					<img src={amex} alt="amex" className="two" />
					<img src={master} alt="master" className="three" />
					<img src={visa} alt="visa" className="four" />
					card number
					<CardNumberElement
						type="text"
						className="account-field"
						id="cardNumber"
						name="cardNumber"
					/>
				</label>
			</div>
			<div className="account-row">
				<label htmlFor="expiry">
					Expiry
					<CardExpiryElement type="text" className="account-field" id="expiry" name="expiry" />
				</label>
				<label htmlFor="cvc">
					CVC
					<CardCvcElement type="text" className="account-field" id="cvc" name="cvc" />
				</label>
			</div>
			<div className="account-row">
				<label htmlFor="country">
					Country
					<Select
						className="country"
						value={billingDetails.country}
						id="country"
						name="country"
						onChange={country => setBillingDetails({ country: country })}
					>
						{COUNTRIES_LIST.map(c => (
							<Select.Option value={c.code} key={c.code}>
								{c.name}
							</Select.Option>
						))}
					</Select>
				</label>
				<label htmlFor="postalcode">
					Postal code
					<input
						className="account-field"
						type="text"
						placeholder="90210"
						id="postalcode"
						name="postalcode"
						value={billingDetails.postalCode}
						onChange={e => setBillingDetails({ postalCode: e.target.value })}
					/>
				</label>
			</div>
		</div>
	)
}

export default Card
