import * as auth from "../util/auth";
import React from "react";
import { navigate } from "gatsby";

function withUser(Component){
    return function(props){
        const user = auth.getUserInfo();

        React.useEffect(
            () => {
                if(!user) navigate("/");
            },
            [user]
        )

        return user ? <Component {...props} /> : null;
    }
}

export default withUser;