import React from 'react'
import Subscription from '../subscribe/subscribe.component'
import './subscriptions.styles.scss'
import { usePlans } from '../../hooks/stripe'
import AsyncContainer from '../async-container/async-container.component'

const stripeKey =
	process.env.NODE_ENV === 'production'
		? 'https://icloud-ready.com/stripe_customer_id'
		: 'https://icloud-ready.com/stripe_customer_id2'

function filterPlans(plan) {
	return plan.metadata.planType === 'monthly' || plan.isSubscribed
}

function Subscriptions({ user }) {
	const stripeId = user[stripeKey]
	//console.log('stripeId in sub' + stripeId)
	const { data: plans, isLoading, error } = usePlans({ stripeId })

	return (
		<div className="subscriptions">
			<AsyncContainer isLoading={isLoading} error={error} isError={Boolean(error)}>
				{() =>
					plans
						.filter(filterPlans)
						.map(plan => (
							<Subscription
								key={plan.id}
								plan={plan}
								stripeId={stripeId}
								user={user}
							/>
						))
				}
			</AsyncContainer>
		</div>
	)
}

export default Subscriptions
