import React, { useState } from 'react'
import { navigate } from 'gatsby-link'
import './subscribe.styles.scss'
import mark from '../../images/mark.svg'
import createStripePortalSession from '../util/createStripePortalSession'
import Spinner from 'react-bootstrap/Spinner'
import { useEditSubscription, useCancelSubscription } from '../../hooks/stripe'
import ProceedDialog from '../proceed-dialog/proceed-dialog.component'
import { getUserInfo } from '../util/auth'
import updateStripeCustomer from '../util/updateStripeCustomer'

function convertDateToString(ms) {
	const date = new Date(ms)
	const m = date.getMonth() + 1
	const y = date.getFullYear()
	const d = date.getDate()
	return `${d}/${m}/${y}`
}

const MyAccount = ({ plan, stripeId }) => {
	const { id, isSubscribed, amount, nickname: name, subscriptionData } = plan
	const { cancel, isLoading: isCanceling } = useCancelSubscription()
	const { edit, isLoading: isEditing } = useEditSubscription()
	const inputRef = React.useRef(null)
	const [submitted, setSubmitted] = useState(false)
	const user = getUserInfo()

	const startBillingDashboard = ({ stripeId }) => {
		createStripePortalSession(stripeId)
			.then(res => res.json())
			.then(data => navigate(data.customer.url))
			.catch(err => console.error(err))
	}

	const handleBuyNow = React.useCallback(() => {
		const state = { planId: id, planName: name }
		navigate('/trial', { state })
	}, [id])

	const handleCancel = async e => {
		cancel({
			stripeId: subscriptionData.customer,
			subscriptionId: subscriptionData.id,
		})

		await updateStripeCustomer({
			customerId:
				process.env.NODE_ENV === 'production'
					? user?.['https://icloud-ready.com/stripe_customer_id']
					: user?.['https://icloud-ready.com/stripe_customer_id2'],
			metadata: {
				realmId: undefined,
			},
		})
	}

	const handleEdit = () => {
		// console.log(inputRef.current.value);
		edit({
			stripeId,
			planId: id,
			subscriptionItemId: subscriptionData.items.data[0].id,
			subscriptionId: subscriptionData.id,
			quantity: inputRef.current.value,
		})
	}

	return (
		<div className="subscribe">
			<div className="subscribe-content">
				<div className="product-parent">
					{/* <div
            className="check-parent"
            onClick={() => setActive(!active)}
            onChange={() => {}}
            aria-hidden="true"
          >
            {active ? <img src={mark} alt="mark" /> : null}
          </div> */}
					<div className="product">{name}</div>
				</div>
				{isSubscribed ? (
					<>
						<div className="date">
							Renewal Date{' '}
							{convertDateToString(subscriptionData.current_period_end * 1000)}
						</div>
						{/* <div className="user-parent">
              <p className="user">Users:</p>
              <input
                className="users"
                type="number"
                defaultValue={subscriptionData.items.data[0].quantity}
                min={plan.metadata.minUsers}
                ref={inputRef}
              />
            </div> */}
						<div className="actions-parent">
							{/* <ProceedDialog
                question="Are you sure you want to update users count?"
                onClick={handleEdit}
              >
                <button
                  className={`submit${submitted ? ' submitted' : ''}`}
                  aria-hidden="true"
                  disbaled={isEditing}
                >
                  {
                    isEditing ? <Spinner variant="light" animation="border" size="sm" /> : "Upgrade Users"
                  }
                </button>
              </ProceedDialog> */}

							<ProceedDialog
								question="Are you sure you want to cancel this subscription?"
								onClick={handleCancel}
							>
								<button
									className="cancel"
									aria-hidden="true"
									disabled={isCanceling}
								>
									{isCanceling ? (
										<Spinner
											variant="light"
											animation="border"
											size="sm"
										/>
									) : (
										'Cancel'
									)}
								</button>
							</ProceedDialog>
						</div>
					</>
				) : (
					<div className="cart" onClick={handleBuyNow}>
						Buy Now
					</div>
				)}
			</div>
		</div>
	)
}

export default MyAccount
