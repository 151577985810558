import React from 'react';
import './infoCard.styles.scss';
import COUNTRIES_LIST from "../../utils/countries";

const InfoCard = ({ accountData, customerData, setCustomerData }) => {
  const handleChange = e => {
    setCustomerData({...customerData, [e.target.name]: e.target.value});
  }

  const {
    name: fullName,
    email,
    nickname: userName,
    mobile,
    companyName,
    companyWebSite,
    country,
    city,
    address,
  } = accountData;

  return (
    <div className="info-card">
      <div className="info-row">
        <div className="account-row">
          <label htmlFor="fullName">
            Full Name
            <input
              className="account-field"
              type="text"
              value={fullName}
              id="fullName"
              name="fullName"
              readOnly
            />
          </label>
          <label htmlFor="email">
            Email Address
            <input
              className="account-field"
              type="text"
              value={email}
              id="email"
              name="email"
              readOnly
            />
          </label>
        </div>
        <div className="account-row">
          <label htmlFor="userName">
            User Name
            <input
              className="account-field"
              type="text"
              value={userName}
              id="userName"
              name="userName"
              readOnly
            />
          </label>
          {/* <label htmlFor="mobile">
            Mobile Number
            <input
              className="account-field"
              type="text"
              value={mobile}
              id="mobile"
              name="mobile"
              readOnly
            />
          </label> */}
        </div>
      </div>
      <div className="info-row">
        <div className="account-row">
          <label htmlFor="company">
            Company
            <input
              className="account-field"
              type="text"
              value={companyName || customerData.company}
              id="company"
              name="company"
              onChange={handleChange}
            />
          </label>
          <label htmlFor="website">
            Company Website
            <input
              className="account-field"
              type="text"
              value={companyWebSite || customerData.companyWebsite}
              id="website"
              name="companyWebsite"
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="account-row">
          <label className="select" htmlFor="country">
            Country
            <select
              className="account-field"
              type="text"
              value={customerData.country || "US"}
              id="country"
              name="country"
              onChange={handleChange}
            >
              {
                COUNTRIES_LIST.map(c => (
                  <option value={c.code} key={c.code}>{c.name}</option>
                ))
              }
            </select>
          </label>
          {/* <label className="select" htmlFor="city">
            City/State
            <select
              className="account-field"
              type="text"
              defaultValue={city}
              id="city"
              name="city"
            >
              <option value="United States">United States</option>
              <option value="Egypt">Egypt</option>
              <option value="China">China</option>
            </select>
          </label> */}
        </div>
      </div>

      <div className="account-row">
        <label className="full" htmlFor="address">
          Address
          <input
            className="account-field"
            type="text"
            value={customerData.address}
            id="address"
            name="address"
            onChange={handleChange}
          />
        </label>
      </div>
    </div>
  );
};

export default InfoCard;
