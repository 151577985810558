import React, { useEffect, useState } from 'react'
import './billing.styles.scss'
import Card from '../card/card.component.jsx'
import InfoCard from '../infoCard/infoCard.component'
import check from '../../images/ok.svg'
import visa from '../../images/bank_VISA.svg'
import master from '../../images/bank_Mastercard.svg'
import amex from '../../images/bank_AMEX.svg'
import discover from '../../images/bank_Discover.svg'
import { useStripe, useElements } from '@stripe/react-stripe-js'
import Spinner from 'react-bootstrap/Spinner'
import deleteIcon from '../../images/delete.svg'
import { useRemoveCard, usePaymentMethods, useAddCard } from '../../hooks/stripe'
import ProceedDialog from '../proceed-dialog/proceed-dialog.component'
import { useCustomer, useEditCustomer } from '../../hooks/stripe'

const cardIconMapper = {
	visa,
	master,
	amex,
	discover,
}

const Billing = ({ user }) => {
	const [activeMethod, setActiveMethod] = useState(0)
	const [billingDetails, setBillingDetails] = React.useReducer((s, a) => ({ ...s, ...a }), {
		country: 'US',
		postalCode: '',
	})
	const stripeId =
		process.env.NODE_ENV === 'production'
			? user?.['https://icloud-ready.com/stripe_customer_id']
			: user?.['https://icloud-ready.com/stripe_customer_id2']
	const stripe = useStripe()
	const elements = useElements()
	const { data: methods, isLoading: isMethodsLoading, error } = usePaymentMethods({ stripeId })
	const { mutate: removeCard, isLoading: isRemoving } = useRemoveCard()
	const { mutate: addCard, isLoading: isAddingCard, error: addCardError, setError } = useAddCard()
	const { data: customer } = useCustomer()
	const [customerData, setCustomerData] = React.useState(customer?.metadata || {})
	const { mutate: editCustomer, isLoading: isEditing } = useEditCustomer()

	React.useEffect(() => {
		if (customer) setCustomerData(customer?.metadata || {})
	}, [customer])

	// console.log(methods)

	const handleAddCard = async () => {
		setError(null)
		// Do nothing if stripe hasn't loaded yet
		if (!stripe || !elements) return

		const cardNumberElement = elements.getElement('cardNumber')
		const { token } = await stripe
			.createToken(cardNumberElement, {
				address_country: billingDetails.country,
				address_zip: billingDetails.postalCode,
			})
			.catch(setError)

		if (!token) {
			setError({ message: 'please proivde a valid card credentials' })
		} else {
			addCard({ customerId: stripeId, token })
		}
	}

	const handleEdit = e => {
		editCustomer({ customerId: stripeId, metadata: customerData })
	}

	return (
		<>
			<div className="my-billing">
				<p className="title">Welcome {user.name}</p>
				{isMethodsLoading ? (
					<h4>Loading</h4>
				) : !methods.length ? (
					<h4>You have no saved payment methods</h4>
				) : (
					<form className="billing-parent">
						<div className="methods-wrapper">
							<div className="methods-parent">
								<div className="methods">
									<p className="methods-title">Payment Methods</p>
									<div className="methods-list">
										{methods.map(({ id, card }, index) => (
											<div className="method-content" key={id}>
												<img
													src={
														cardIconMapper[
															card.brand
														]
													}
													width="35"
													height="21"
													alt={card.brand}
												/>
												<p className="method-num">
													<span>**** **** ****</span>
													{card.last4}
												</p>
												<p className="method-date">
													{card.exp_month} /{' '}
													{card.exp_year}
												</p>
												<div
													className={`check-parent${
														activeMethod === index
															? ' checked'
															: ''
													}`}
													onClick={() =>
														setActiveMethod(index)
													}
													onChange={() => {}}
													aria-hidden="true"
												>
													{activeMethod === index ? (
														<img
															src={check}
															width="21"
															height="21"
															alt="check"
														/>
													) : null}
												</div>
												<ProceedDialog
													question="Are you sure you want to delete this card?"
													onClick={() =>
														removeCard({
															customerId:
																stripeId,
															cardId: id,
														})
													}
												>
													<div className="edit-parent">
														<img
															src={
																deleteIcon
															}
															width="21"
															height="21"
															alt="edit"
														/>
													</div>
												</ProceedDialog>
											</div>
										))}
									</div>
									<ProceedDialog
										question="You are going to add this card to your payment methods , are you sure?"
										onClick={() => handleAddCard()}
									>
										<button
											className="add-card"
											type="button"
											disabled={!stripe || isAddingCard}
										>
											{isAddingCard ? (
												<Spinner
													varirant="primary"
													animation="border"
													size="sm"
												/>
											) : (
												'Add Card'
											)}
										</button>
									</ProceedDialog>
								</div>
								<Card
									billingDetails={billingDetails}
									setBillingDetails={setBillingDetails}
									cardError={addCardError}
								/>
							</div>
						</div>
						<div className="info-parent">
							<p className="info-title">Payment Information</p>
							<InfoCard
								accountData={user}
								customerData={customerData}
								setCustomerData={setCustomerData}
							/>
						</div>
						<div className="actions">
							{/* <button className="reset-btn" type="reset">
                Cancel
              </button> */}
							<ProceedDialog
								question="Are you sure you want to update you info?"
								onClick={handleEdit}
							>
								<button
									className="submit-btn"
									type="button"
									disabled={isEditing}
								>
									{isEditing ? (
										<Spinner
											varirant="primary"
											animation="border"
											size="sm"
										/>
									) : (
										'Submit'
									)}
								</button>
							</ProceedDialog>
						</div>
					</form>
				)}
			</div>
		</>
	)
}

export default Billing
